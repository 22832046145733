.content {
  min-height: 80vh;
}

/*
  Home Styles
*/
.text-link {
  color: #007bff;
  text-decoration: none;
}
text-link:hover {
  text-decoration: underline !important;
}


/*
  Footer Styles
*/
.footer-icon {
  color: lightgray;
  transition: all 0.3s;
}

.footer-icon:hover {
  color: #007bff;
}